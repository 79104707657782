import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ children, title, description }) => {
    const { site } = useStaticQuery(query);

    const { defaultTitle, defaultDescription } = site.siteMetadata;
    const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
    };

    return (
        <Helmet title={seo.title} titleTemplate={`%s - ${defaultTitle}`}>
            <meta name="description" content={seo.description} />
            {children}
        </Helmet>
    );
};

const query = graphql`
    query {
        site {
            siteMetadata {
                defaultTitle: title
                defaultDescription: description
            }
        }
    }
`;

export default SEO;
